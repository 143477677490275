<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{title}}</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="pb-2">
                    <form  class="row gy-1 gx-2" onsubmit="return false" novalidate="novalidate">
                        <div class="col-12 px-2">
                            <label class="form-label" for="chartName">Account Name</label>
                            <div class="input-group input-group-merge">
                                <input
                                    v-model="formData.name"
                                    id="chartName"
                                    class="form-control add-credit-card-mask" 
                                    type="text"
                                    placeholder="Account Name"
                                >
                                <span class="input-group-text cursor-pointer p-25" >
                                    <span class="add-card-type"></span>
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-12 mb-1 px-2">
                            <label class="form-label" for="select2-basic">Parent Group</label>
                            <v-select 
                                v-model="formData.account_head_id"
                                :options="groups" 
                                label="name"
                                :reduce="name => name.id"
                            />
                        </div>

                        <div v-show="enableSlugEdit && isGroup" class="col-12 px-2">
                            <label class="form-label" for="modalEditUserStatus">Default Account Type</label>

                            <select
                                class="form-select"
                                v-model="formData.account_slug"
                            >
                                <option
                                    v-for="(slug, key) in accountSlug"
                                    :key="key"
                                    :value="key"
                                >
                                    {{slug}}
                                </option>
                            </select>
                        </div>
                        
                        <div class="col-12 text-center">
                            <hr class="m-0">
                            <button @click="$emit(eventName)" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Update
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from "vue";

export default {
    name: 'ChartCreateModal',
    data: () => ({
        isOpen: false
    }),
    methods: {
        toggleModal () {
            this.isOpen = !this.isOpen
        }
    },
    computed: {
        enableSlugEdit: () => process.env.VUE_APP_ENABLE_SLUG_EDIT === 'true',
    },
    props: {
        formData: Object,
        accountSlug: Object,
        groups: {
            type: Array,
            default: []
        },
        isGroup: Boolean,
        loading: Boolean,
        title: String,
        eventName: String
    }
}
</script>

<style scoped>
.btn-close {
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
  }
@media screen and (min-width: 1200px) {
  .btn-close {
    width: 30px;
    height: 30px;
    background-color: #E4E6EB;
    border-radius: 50%;
  }
  .btn-close:hover {
    width: 30px;
    height: 30px;
    background-color: #D8DADF;
  }
}
</style>